//
// Services Taxonomy
// --------------------------------------------------

.tax-service {
  .alm-btn-wrap {
    display: none;
  }
  & > section {
    padding-top: 50px;
    background-color: #EAEAEA;
  }

  .profile-banner {
    height: 145px;
    margin: 0 -15px -15px;
    background-size: cover;
  }

  .page-banner {
    @extend .box;
    background: #444;
    color: #fff;

    margin-bottom: 0;

    &.open {
      .service-title {
        position: relative;
        z-index: 1004;

        &:after {
          content: "\f106";
        }
      }
      .service-list-container {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .service-title {
    display: inline-block;
    border-bottom: 2px solid transparent;
    padding-bottom: 6px;

    cursor: pointer;

    &:after {
      @extend .fa;
      content: "\f107";
    }

    &:hover {
      border-bottom-color: #fff;
    }
  }

  .service-list-container {
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;

    padding-top: 50px;

    z-index: 1001;

    @include transition(all 200ms ease-out);
  }

  .service-list {
    position: absolute;
    top: 172px;
    max-height: calc(100% - 172px);
    left: 0;
    width: 100%;
    z-index: 1005;
    overflow: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0 10px;

      font-size: 24px;

      li {
        margin: 5px 0;

        a {
          color: #fff;
        }

        &.active {
          & > a {
            color: $brand-pink;
          }
        }
      }

      ul {
        font-size: 20px;
        margin-bottom: 20px;

        li {
          a {
            color: #ddd;
          }
        }
      }
    }
  }

  .profile {
    @extend .box;
  }

  .not-found {
    .fa-ul {
      li {
        margin: 5px 0;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .tax-service {
    .service-title {
      font-size: 25px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .tax-service {
    .service-list {
      top: 160px;
      max-height: calc(100% - 160px);
    }
  }
}