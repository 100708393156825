//
// Profile
// --------------------------------------------------

body.home {
  & > section {
    padding-bottom: 0;
  }

  & > footer {
    margin-top: 0;
  }

  .page-banner {
    padding: 0;
    padding-top: 34%;
    background-repeat: no-repeat;
    background-size: cover;

    .container, .row, .col-xs-12 {
      height: 100%;
    }

    .banner-content {
      position: absolute;
      top: 100%;

      width: 100%;

      color: #fff;
      text-shadow: 1px 1px 1px #333;
    }

    h1 {
      font-size: 30px;
      font-weight: 300;
      text-align: center;

      @media (max-width: $screen-sm-max) {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .contact-us-button {
    margin: auto;
    width: 260px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 7px;
    padding: 10px 50px;
    color: #fff;
    margin-top: 60px;
    text-align: center;
    cursor: pointer;

    @media (min-width: $screen-md-min) {
      margin: auto;
      width: 510px;
      font-size: 30px;
      font-weight: 200;
      border-radius: 7px;
      padding: 10px 50px;
      color: #fff;
      text-align: left;
    }
  }

  .contact-us-bar {
    background-color: #f5f5f5;
    width: 100%;
    display: none;
    padding-top: 20px;
    padding-bottom: 40px;

    @media (min-width: 1000px) {
      .contact-us-bar {
        padding-bottom: 100px;
      }
    }
  }

  .contact-us-bar-inner {
    padding-left: 40px;
    padding-right: 40px;

    @media (min-width: 1000px) {
      width: 800px;
      margin: auto;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .service p {
    font-size: 18px;
    font-weight: 200;
    padding-top: 10px;

    @media (min-width: 1000px) {
      width: 100%;
    }
  }

  .services-bar {
    background-color: #343434;
    width: 100%;
    padding: 145px 0 55px;
    text-align: center;
    color: #fff;

    > p {
      font-weight: 400;
      letter-spacing: 1px;
    }
  }

  .services-bar-inner {
    @media (min-width: 1000px) {
      padding-top: 20px;
      width: 950px;
      float: none !important;
      margin: auto !important;
    }
  }

  .service {
    position: relative;
    margin-top: 30px;

    @media (min-width: 1000px) {
      height: 300px;
    }

    p {
      width: 280px;
      margin: auto;

      @media (min-width: 1000px) {
        font-size: 18px;
        width: 400px;
      }
    }
  }

  .service-icon {
    width: 100%;
    font-size: 50px;
  }
}

@media (min-width: $screen-md-min) {
  body.home {
    .page-banner {
      padding-top: 37%;

      .banner-content {
        top: 10%;
      }

      h1 {
        margin: 0;
        font-size: 40px;
        text-align: left;
        max-width: 440px;
      }
    }

    .services-bar {
      padding-top: 55px;
    }
  }
}