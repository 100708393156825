//
// Header
// --------------------------------------------------

.page-banner {
  width: 100%;

  background-color: #EAEAEA;
  text-align: center;
  position: relative;
  padding: 20px 0;

  .j18-logo {
    img {
      width: 160px;
    }
  }
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  height: 50px;

  background: #000;

  & > .container {
    position: relative;
  }

  .menu-logo {
    display: inline-block;
    padding: 12px 10px 10px 0;

    img {
      max-width: 100%;
    }
  }

  .menu-toggle {
    position: absolute;
    top: 1px;
    right: 10px;
    z-index: 1002;

    width: 40px;
    height: 40px;

    cursor: pointer;

    .icon-bar {
      background-color: #fff;

      height: 3px;
      width: 30px;

      position: absolute;
      top: 11px;
      left: 10px;

      @include transition(all 300ms ease-out);

      &:nth-child(2) {
        top: 22px;
        left: 10px;
        opacity: 1;
        filter: alpha(opacity=100);
      }

      &:last-child {
        top: 33px;
        left: 10px;
      }
    }
  }

  &.open {
    .menu-toggle {
      .icon-bar {
        &:first-child {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: 23px;
        }

        &:nth-child(2) {
          opacity: 0;
          filter: alpha(opacity=0);
        }

        &:last-child {
          -ms-transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 23px;
        }
      }
    }

    .main-menu-container {
      visibility: visible;
      opacity: 1;
    }
  }
}

.main-menu-container {
  background-color: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;

  padding-top: 50px;

  z-index: 1001;

  @include transition(all 200ms ease-out);

  & > .menu {
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: 100%;
    overflow: auto;
    list-style: none;

    @extend .container;

    li {
      margin: 0 0 5px;

      &:first-child {
        border-bottom: 2px solid #777;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      &.current-menu-item {
        a {
          color: $brand-pink;
        }
      }
    }

    a {
      font-size: 25px;
      color: #fff;

      &:hover {
        color: #ddd;
        text-decoration: none;
      }
    }
  }
}