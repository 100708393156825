//
// Profile
// --------------------------------------------------

.single-profile {
  .page-wrap {
    background-color: #EAEAEA;
  }

  .profile-banner {
    height: 33vw;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .profile-header {
    margin: 0;
  }

  .profile-image {
    border: 2px solid #fff;
    border-radius: 5px;
    overflow: hidden;

    position: absolute;
    left: 15px;
    top: -50px;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .profile-title {
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 700;
  }

  .profile-social {
    a {
      display: inline-block;
      margin: 0 10px 0 0;

      color: #00aced;

      &.facebook {
        color: #3b5998;
      }

      &.linkedin {
        color: #007bb5;
      }

      &:hover {
        color: $brand-pink;
      }

      i {
        font-size: 35px;
      }
    }
  }

  .profile-contact {
    margin-top: 10px;

    address {
      font-size: 14px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    ul {
      margin: 10px 0 0;

      li {
        margin: 3px 0;
      }
    }
  }

  .profile-body {
    margin-top: 40px;
  }

  .services {
    a {
      display: inline-block;
      padding: 0 4px 1px;
      background: $brand-green;
      border-radius: 5px;
      color: #fff;
      margin: 1px 2px;

      &:hover {
        background: darken($brand-green, 10%);
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .single-profile {
    .profile-header {
      margin: 0 -15px;
      padding-top: 20px;
    }

    .profile-title {
      font-size: 24px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .single-profile {
    .profile-banner {
      .profile-footer {
        top: auto;
        bottom: 0;
      }
    }

    .profile-header {
      max-height: 210px;
      overflow: hidden;
      padding-left: 225px;
    }

    .profile-image {
      top: auto;
      bottom: 0;
      border-width: 5px;

      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}