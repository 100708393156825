//
// Footer
// --------------------------------------------------

body > footer {
  &.footer-bar {
    background-color: #1F1F1F;
    color: #fff;
    padding: 10px 0;

    font-size: $font-size-small;

    a {
      color: #fff;
    }
  }

  .ultimate {
    img {
      max-width: 100%;
    }
  }
}

@media (min-width: $screen-md-min) {
  body > footer {
  }
}