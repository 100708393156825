//
// Global
// --------------------------------------------------

body {
  padding-top: 50px;

  font-size: 16px;
  font-family: 'Roboto', serif;
  font-weight: 300;

  &.sticky {
    overflow: hidden;
  }

  & > section {
    padding-bottom: 70px;
  }
}

.entry-title {
  margin-top: 40px;
}

.container {
  max-width: 920px;
}

.intro-bar {
  padding: 60px 20px 70px 20px;
  font-weight: 300;

  @media (min-width: 1000px) {
    padding: 70px 90px;
  }

  p {
    text-align: center;
  }
}

.intro-list {
  float: left;
}

.screen-reader-response {
  color: red;
  margin-bottom: 50px;
  margin-top: 50px;

  ul {
    margin-left: 22px;
  }
}

.wpcf7-not-valid-tip {
  color: red;
  margin-bottom: 50px;
  line-height: 40px;
}

.wpcf7 {
  margin-top: -45px;

  @media (min-width: 1000px) {
    margin-top: 0px;
  }

  input {
    padding: 10px;
    border-radius: 4px;
    border: none;
    width: 100%;

    @media(min-width: 1000px) {
      padding: 10px;
    }

    &[type="submit"] {
      margin-top: -5px;
      font-size: 20px;
      font-weight: 500;
    }
  }

  textarea {
    padding: 10px;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 220px;

    @media (min-width: 1000px) {
      height: inherit;
    }
  }
}

.box {
  background: #fff;
  padding: 15px;
  box-shadow: 0 3px 17px #aaa;
  border-radius: 2px;
  margin-bottom: 25px;

  .box-title {
    margin: -15px -15px 15px;
    background-color: #eee;
    color: #555;
    padding: 15px;
    font-size: 20px;

    &.blue {
      background-color: $brand-blue;
    }

    &.green {
      background-color: $brand-green;
    }

    &.pink {
      background-color: $brand-pink;
    }
  }

  .box-footer {
    margin: 15px -15px -15px ! important;
    background-color: #ddd;
    color: #555;
    padding: 10px 15px;
    font-size: 14px;

    &.blue {
      background-color: $brand-blue;
    }

    &.green {
      background-color: $brand-green;
    }

    &.pink {
      background-color: $brand-pink;
    }
  }

  h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.hentry {
  header {
    & > :first-child {
      margin-top: 0;
    }
  }
}

.wpcf7-form p {
  margin-bottom: 15px;
}